<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Create App Client
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card>
      <validation-observer
        ref="appClientCreateForm"
        #default="{invalid}"
      >
        <b-form @submit.prevent="submitForm">
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="App Client*"
                label-for="h-app-client-app"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="App Client"
                  vid="app"
                  rules="required"
                >
                  <b-form-input
                    id="h-app-client-app"
                    v-model="app"
                    placeholder="App Client"
                    :state="(errors.length > 0 || appValidation) ? false : null"
                    name="app"
                    @input="appValidation == true ? appValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="appValidation"
                    class="text-danger"
                  >
                    {{ appError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6" />

            <b-col cols="6">
              <b-form-group
                label="App Token*"
                label-for="h-app-client-token"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="App Token"
                  vid="token"
                  rules="required"
                >
                  <b-form-input
                    id="h-app-client-token"
                    v-model="token"
                    placeholder="App Token"
                    :state="(errors.length > 0 || tokenValidation) ? false : null"
                    name="token"
                    @input="tokenValidation == true ? tokenValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="tokenValidation"
                    class="text-danger"
                  >
                    {{ tokenError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6" />

            <b-col cols="6">
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules="required"
              >
                <b-form-group
                  label="Status*"
                  label-for="h-app-client-status"
                  label-cols-md="3"
                  :state="(errors.length > 0 || statusValidation) ? false : null"
                >
                  <v-select
                    id="h-app-client-status"
                    v-model="status"
                    label="title"
                    :options="option"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="statusValidation == true ? statusValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="statusValidation"
                    class="text-danger"
                  >
                    {{ statusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="6" />
          </b-row>

          <b-nav class="wrap-border save-nav">
            <li
              class="nav-item ml-auto"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                :to="{ name: 'directory-app-clients-index' }"
              >
                Cancel
              </b-button>
            </li>
            <li
              class="nav-item mr-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                :disabled="invalid"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span class="align-middle">Save App</span>
              </b-button>
            </li>
          </b-nav>

        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BNav,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BNav,

    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      app: '',
      token: '',
      status: 'active',
      appError: 'Valid app is required',
      appValidation: false,
      tokenError: 'Valid token is required',
      tokenValidation: false,
      statusError: 'Valid status is required',
      statusValidation: false,
      option: [{ title: 'Active', code: 'active' }, { title: 'Inactive', code: 'inactive' }],

      // validation rules
      required,
    }
  },
  methods: {
    submitForm() {
      this.$refs.appClientCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('app', this.app)
          formData.append('token', this.token)
          formData.append('status', this.status)

          this.$http.post('directory/app-clients/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message ?? '',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.$router.push({ name: 'directory-app-clients-index' })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'app') {
                    this.appError = validationError.msg
                    this.appValidation = true
                  } else if (validationError.param === 'token') {
                    this.tokenError = validationError.msg
                    this.tokenValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
